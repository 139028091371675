import { DOM } from './_variables';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

const
  animations = {
    revealTextByLine: () => import('./animations/reveal-text-by-line'),
    accordionAnimation: () => import('./_accordions'),
    scrollReveal: () => import('./animations/scroll-reveal')
  },
  toRevealTexts = document.querySelectorAll('.text-reveal'),
  currentTime = document.querySelector('.current-time')

export default {
  scrollReveal: () => {
    animations.scrollReveal().then(module => {
      module.reveal(DOM.toRevealElements)
    })
  },
  splitAndRevealText: () => {
    if (toRevealTexts.length > 0) {
      animations.revealTextByLine().then(module => {
        for (const text of toRevealTexts) {
          module.revealTextByLine(text)
        }
      })
    }
  },

  backToTop: () => {
    const backToTopTrigger = document.querySelector('.back-top')

    if (backToTopTrigger) {
      backToTopTrigger.addEventListener('click', () => {
        gsap.to(window, { duration: 1, scrollTo: { y: 0, x: 0 } });
      })
    }
  },

  marquee: () => {
    let loops = gsap.utils.toArray('.marquee__els-container').map((line, i) => {
      const innerElements = line.querySelectorAll('.marquee__el'),
        horizontalLoop = (items, config) => {
          /* Based off GreenSock's pen here: https://codepen.io/GreenSock/pen/jOBBVjr  */

          items = gsap.utils.toArray(items);
          config = config || {}
          let tl = gsap.timeline({
            repeat: config.repeat,
            paused: config.paused,
            defaults: { ease: "none" },
            onReverseComplete: () => tl.totalTime(tl.rawTime() + tl.duration() * 100)
          }),
            length = items.length,
            startX = items[0].offsetLeft,
            times = [],
            widths = [],
            xPercents = [],
            curIndex = 0,
            pixelsPerSecond = (config.speed || 1) * 100,
            snap = config.snap === false ? v => v : gsap.utils.snap(config.snap || 1), // some browsers shift by a pixel to accommodate flex layouts, so for example if width is 20% the first element's width might be 242px, and the next 243px, alternating back and forth. So we snap to 5 percentage points to make things look more natural
            totalWidth, curX, distanceToStart, distanceToLoop, item, i
          gsap.set(items, { // convert "x" to "xPercent" to make things responsive, and populate the widths/xPercents Arrays to make lookups faster.
            xPercent: (i, el) => {
              let w = widths[i] = parseFloat(gsap.getProperty(el, "width", "px"))
              xPercents[i] = snap(parseFloat(gsap.getProperty(el, "x", "px")) / w * 100 + gsap.getProperty(el, "xPercent"))
              return xPercents[i]
            }
          });
          gsap.set(items, { x: 0 });
          totalWidth = items[length - 1].offsetLeft + xPercents[length - 1] / 100 * widths[length - 1] - startX + items[length - 1].offsetWidth * gsap.getProperty(items[length - 1], "scaleX") + (parseFloat(config.paddingRight) || 0);
          for (i = 0; i < length; i++) {
            item = items[i];
            curX = xPercents[i] / 100 * widths[i];
            distanceToStart = item.offsetLeft + curX - startX;
            distanceToLoop = distanceToStart + widths[i] * gsap.getProperty(item, "scaleX");
            tl.to(item, {
              xPercent: snap((curX - distanceToLoop) / widths[i] * 100),
              duration: distanceToLoop / pixelsPerSecond
            }, 0)
              .fromTo(item, { xPercent: snap((curX - distanceToLoop + totalWidth) / widths[i] * 100) }, {
                xPercent: xPercents[i],
                duration: (curX - distanceToLoop + totalWidth - curX) / pixelsPerSecond,
                immediateRender: false
              }, distanceToLoop / pixelsPerSecond)
              .add("label" + i, distanceToStart / pixelsPerSecond);
            times[i] = distanceToStart / pixelsPerSecond;

            /*item.addEventListener("mouseenter", () => gsap.to(tl, {timeScale: 0, overwrite: true}));
            item.addEventListener("mouseleave", () => gsap.to(tl, {timeScale: 1, overwrite: true}));*/

          }

          function toIndex(index, vars) {
            vars = vars || {};
            (Math.abs(index - curIndex) > length / 2) && (index += index > curIndex ? -length : length); // always go in the shortest direction
            let newIndex = gsap.utils.wrap(0, length, index),
              time = times[newIndex];
            if (time > tl.time() !== index > curIndex) { // if we're wrapping the timeline's playhead, make the proper adjustments
              vars.modifiers = { time: gsap.utils.wrap(0, tl.duration()) };
              time += tl.duration() * (index > curIndex ? 1 : -1);
            }
            curIndex = newIndex;
            vars.overwrite = true;
            return tl.tweenTo(time, vars);
          }

          tl.next = vars => toIndex(curIndex + 1, vars);
          tl.previous = vars => toIndex(curIndex - 1, vars);
          tl.current = () => curIndex;
          tl.toIndex = (index, vars) => toIndex(index, vars);
          tl.times = times;
          if (config.reversed) {
            tl.vars.onReverseComplete();
            tl.reverse();
          }
          return tl;
        }

      horizontalLoop(innerElements, {
        repeat: -1,
        speed: 2,
        reversed: false,
        paddingRight: parseFloat(gsap.getProperty(innerElements[0], 'marginRight', 'px'))
      })
    })

    let currentScroll = 0;
    let scrollDirection = 1;

    window.addEventListener('scroll', () => {
      let direction = (window.pageYOffset > currentScroll) ? 1 : -1;
      if (direction !== scrollDirection) {
        loops.forEach(tl => gsap.to(tl, { timeScale: direction, overwrite: true }));
        scrollDirection = direction;
      }
      currentScroll = window.pageYOffset;
    })
  },

  ShowHideElements: () => {
      (function (window, $) {


        $('.ham').click(function () {
          $(this).toggleClass('open');
          $('.burger-menu').toggleClass('open');

        });

        $(window).scroll(function() {
          var theta = $(window).scrollTop() / 20 % Math.PI;
          $('#leftgear').css({ transform: 'rotate(' + theta + 'rad)' });
          $('#rightgear').css({ transform: 'rotate(-' + theta + 'rad)' });
        });

        // reveal items while scrolling the page

        var winWidth = $(window).width();
        var winHeight = $(window).height();
        var tollerance = winHeight / 5;

        if(winWidth < 768 ) {
          tollerance = 0;
        }

        function reveal () {

          var count = 1;

          $('.reveal.not-revealed').each(function () {
            var $this = $(this);

            if($(document).scrollTop() + winHeight - tollerance > $this.offset().top && !$this.attr('revealed')) {

              setTimeout(function () {
                $this.removeClass('not-revealed');
              }, count * 200);

              count++;
            }
          });
        }

        setTimeout(function () {
          reveal();
        }, 150);

        $(document).scroll(function () {
          reveal();
        });

        $(window).resize(function () {
          reveal();
        });

        // reveal items 2

        var winWidth2 = $(window).width();
        var winHeight2 = $(window).height();
        var tollerance2 = winHeight2 / 50;

        if(winWidth2 < 768 ) {
          tollerance2 = 0;
        }

        function reveal2 () {

          var count2 = 1;

          $('.reveal.not-revealed2').each(function () {
            var $this2 = $(this);

            if($(document).scrollTop() + winHeight2 - tollerance2 > $this2.offset().top && !$this2.attr('revealed2')) {

              setTimeout(function () {
                $this2.removeClass('not-revealed2');
              }, count2 * 100);

              count2++;
            }
          });
        }

        setTimeout(function () {
          reveal2();
        }, 150);

        $(document).scroll(function () {
          reveal2();
        });

        $(window).resize(function () {
          reveal2();
        });

        var scrollup;

        $(window).scroll(function (){

          scrollup = $(window).scrollTop();


          if (scrollup>=642){
            $('header .grid').removeClass('invert-header');
          }else {
            $('header .grid').addClass('invert-header');
          }

        });

      })(window, jQuery);
    },

    Parallax: () => {
      var scene = document.getElementById('scene');

      if(scene) {
        var parallaxInstance = new Parallax(scene);
      }

    }
}



