
const
    glide = () => import('./_glide'),
    isHomePage = document.querySelector('.main-home'),
    isEventsPage = document.querySelector('.main-events')

export const sliders = () => {
    if (isHomePage) {
        const welcomeSlider = document.querySelectorAll('.welcome-slider')
        const seaSlider = document.querySelectorAll('.sea-slider')


        if (welcomeSlider.length > 0) {
            const sliderSettings = {
                type: 'carousel',
                startAt: 0,
                perView: 1,
                gap: 0,
                autoplay: 3000,
            }

            for (const slider of welcomeSlider) {

                glide().then(module => module.glideSlider(slider, sliderSettings).mount())
            }
        }

        if (seaSlider.length > 0) {
                    const sliderSettings = {
                        type: 'carousel',
                        startAt: 0,
                        perView: 2,
                        gap: 30,
                        autoplay: 2000,
                    }

                    for (const slider of seaSlider) {

                        glide().then(module => module.glideSlider(slider, sliderSettings).mount())
                    }
                }
    }

    if (isEventsPage) {

            const eventsSlider = document.querySelectorAll('.events-slider')

            if (eventsSlider.length > 0) {
                const sliderSettings = {
                    type: 'carousel',
                    startAt: 0,
                    perView: 1,
                    gap: 0,
                    autoplay: 6000,
                }

                for (const slider of eventsSlider) {

                    glide().then(module => module.glideSlider(slider, sliderSettings).mount())
                }
            }
    }
}