'use strict';

// postcss stylesheets
import './../styles/_tailwind.pcss'
import './../styles/main.pcss'

import global from './_global';
import animations from './_animations';
import { cursor } from "./_cursor";
import { heroSlider } from "./_sliders"
import { welcomeSlider } from "./_sliders"
import { sliders } from "./_sliders"

const lazyLoading = () => import('./_lazy-load')

document.addEventListener("DOMContentLoaded", () => {
  global.pageLoadedChecker()
  global.getSreenSize()
  cursor()
  sliders()
  animations.ShowHideElements()
  //animations.scrollReveal()
  //animations.backToTop()
  lazyLoading().then((module) => module.init())
})

/*document.addEventListener('everyThingIsLoaded', () => {
   animations.marquee()
})*/

